export const Strategy = ({ width, height, className }) => (
  <svg
    className={className}
    fill="#d75e0d"
    height={height || "200px"}
    width={width || "200px"}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 457.042 457.042"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <g id="XMLID_227_">
        {" "}
        <path
          id="XMLID_228_"
          d="M191.913,103V38.914h235.138V208H215.26c-32.157,19.966-29.96,18.705-32.684,20h254.475 c5.522,0,10-4.477,10-10V28.914c0-5.523-4.478-10-10-10H181.913c-5.523,0-10,4.477-10,10V78.75 C171.913,78.75,189.845,88.25,191.913,103z"
        ></path>{" "}
        <path
          id="XMLID_1158_"
          d="M302.327,130.73l-16.316,12.865l6.597,15.902c1.072,2.584,3.178,4.602,5.807,5.562 c2.627,0.959,5.538,0.775,8.024-0.512l43.31-22.404l43.563,33.956c1.826,1.423,3.991,2.113,6.141,2.113 c2.977,0,5.922-1.323,7.894-3.853c3.396-4.356,2.617-10.639-1.739-14.035l-48.615-37.894c-3.08-2.4-7.272-2.788-10.742-0.995 l-39.337,20.349L302.327,130.73z"
        ></path>{" "}
        <path
          id="XMLID_1159_"
          d="M281.081,79.517c-1.81-4.363-6.423-6.866-11.069-5.999l-34.074,6.353l-14.634-23.206 c-2.946-4.672-9.122-6.07-13.792-3.125c-4.672,2.946-6.07,9.121-3.125,13.792L222.64,96.28c2.186,3.466,6.261,5.249,10.292,4.497 l30.647-5.714l18.048-14.231L281.081,79.517z"
        ></path>{" "}
        <path
          id="XMLID_1160_"
          d="M261.774,136.618c0.813,1.309,1.521,2.653,2.148,4.018l44.114-34.784 c4.337-3.419,5.081-9.708,1.661-14.044c-3.42-4.336-9.707-5.082-14.044-1.661l-44.778,35.307 C255.151,128.249,258.903,131.992,261.774,136.618z"
        ></path>{" "}
        <path
          id="XMLID_1161_"
          d="M88.886,264.455c0-2.583,2.094-4.677,4.677-4.677c2.583,0,4.677,2.094,4.677,4.677v95.243 c9.206-5.322,19.854-8.4,31.195-8.454c-5.308-8.741-8.367-18.992-8.367-29.944c0-17.674,7.973-33.513,20.501-44.135 c0,0-0.38-12.025-0.392-147.623c0-2.069,1.664-3.754,3.734-3.779c2.069-0.025,3.774,1.62,3.823,3.688c0,0.001,0,0.001,0,0.001 c0.088,17.425,0.277,55.234,0.332,66.027c0.033,6.537,3.597,12.546,9.318,15.709c5.72,3.163,12.705,2.987,18.259-0.462 l63.98-39.724c8.471-5.259,11.074-16.39,5.814-24.861c-5.259-8.47-16.39-11.076-24.861-5.815l-36.565,22.703 c-0.06-11.926-0.107-21.319-0.169-33.758c-0.118-23.419-19.292-42.51-42.725-42.51h-18.119l-13.566,36.108l-9.245,23.976 l5.418-25.519c0.375-1.766,0.103-3.609-0.767-5.191l-7.34-13.35l6.334-12.091c0.452-0.822,0.436-1.822-0.041-2.63 c-0.478-0.808-1.346-1.303-2.285-1.303H84.612c-0.938,0-1.807,0.495-2.285,1.303c-0.478,0.808-0.493,1.808-0.041,2.63l6.705,12.116 l-7.354,13.374c-0.854,1.552-1.132,3.356-0.787,5.094l4.692,25.567l-8.5-23.976L63.12,86.762H45.001 c-23.44,0-42.606,19.07-42.724,42.51L1.611,261.907c-0.05,9.971,7.992,18.094,17.963,18.145c0.031,0,0.062,0,0.093,0 c9.928,0,18.002-8.024,18.052-17.963l0.667-132.636c0.01-1.975,1.617-3.569,3.591-3.564s3.572,1.607,3.572,3.582l0.008,303.475 c0,11.656,9.204,21.157,20.741,21.641l0.203-40.431c0.096-19.147,8.79-36.312,22.385-47.862V264.455z"
        ></path>{" "}
        <circle id="XMLID_1165_" cx="93.559" cy="37.417" r="37.417"></circle>{" "}
        <circle id="XMLID_1167_" cx="363.092" cy="321.715" r="37.866"></circle>{" "}
        <path
          id="XMLID_1168_"
          d="M455.219,414.671c-0.119-23.721-19.515-43.02-43.237-43.02c-10.17,0-87.895,0-98.086,0 c-22.351,0-40.854,17.134-43.019,38.955c-1.991-22.232-20.794-39.369-43.053-39.369c-10.17,0-87.895,0-98.086,0 c-23.722,0-43.118,19.299-43.237,43.02l-0.215,42.786h36.542l0.214-42.603c0.01-1.946,1.592-3.516,3.537-3.511 c1.945,0.005,3.518,1.584,3.518,3.528v42.585h96.776v-42.688c0-2.112,1.712-3.824,3.824-3.824c2.112,0,3.824,1.712,3.824,3.824 c0,0.028,0,0.057-0.001,0.086l0.214,42.602c26.716,0,46.663,0,72.254,0l0.212-42.188c0.01-1.946,1.592-3.516,3.537-3.511 c1.945,0.005,3.518,1.583,3.518,3.528v42.17h96.776v-41.821c0-2.044,1.605-3.729,3.647-3.828c2.042-0.099,3.805,1.422,4,3.457 c0,0.001,0,0.002,0,0.003l0.212,42.188h36.542L455.219,414.671z"
        ></path>{" "}
        <path
          id="XMLID_1202_"
          d="M178.934,283.434c-20.307,0-36.805,15.955-37.817,35.918c-0.614,12.113,4.534,24.048,14.643,31.885h0 c6.406,4.966,14.442,7.93,23.174,7.93c21.51,0,38.924-17.984,37.817-39.815C215.738,299.361,199.211,283.434,178.934,283.434z"
        ></path>{" "}
      </g>{" "}
    </g>
  </svg>
);
