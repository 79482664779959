import React from "react";
import cn from "classnames";
import styles from "../ServicesGrid/ServicesGrid.module.scss";

const ServicesGrid = ({ className, children }) => {
  const isOdd = children.length % 2 !== 0;
  const gridStyles = cn(styles.servicesGrid, className, {
    [styles.servicesGrid__odd]: isOdd,
  });

  return <ul className={gridStyles}>{children}</ul>;
};

export default ServicesGrid;
