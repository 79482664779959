import React from "react";
import cn from "classnames";
import styles from "../ItemsGrid/ItemsGrid.module.scss";

const ItemsGrid = ({className, children}) => {
  return (
    <div className={cn(styles.itemsGrid, className)}>
      {children}
    </div>
  );
};

export default ItemsGrid;
