export const Vat = ({ width, height, className }) => (
  <svg
    className={className}
    fill="#d75e0d"
    height={width || "200px"}
    width={height || "200px"}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <g>
        {" "}
        <g>
          {" "}
          <path d="M473.872,84.97H38.128C17.07,84.97,0,102.041,0,123.098v265.804c0,21.057,17.07,38.128,38.128,38.128h435.745 c21.057,0,38.128-17.07,38.128-38.128V123.098C512,102.041,494.93,84.97,473.872,84.97z M479.319,388.902 c0,3.008-2.439,5.447-5.447,5.447H38.128c-3.008,0-5.447-2.439-5.447-5.447V123.098c0-3.008,2.439-5.447,5.447-5.447h435.745 c3.008,0,5.447,2.439,5.447,5.447V388.902z"></path>{" "}
        </g>{" "}
      </g>{" "}
      <g>
        {" "}
        <g>
          {" "}
          <path d="M109.638,224.408h39.754c8.388,0,15.747-6.145,16.667-14.482c1.087-9.857-6.6-18.199-16.238-18.199h-15.829v-12.262 c0-8.811-6.779-16.396-15.581-16.785c-9.37-0.414-17.1,6.876-17.1,15.926v15.552c-47.316,9.689-40.086,78.181,8.327,78.181h16.028 c10.326,0,10.33,15.251,0,15.251H85.911c-8.388,0-15.747,6.145-16.667,14.482c-1.087,9.857,6.602,18.199,16.238,18.199h15.828 v12.262c0,8.811,6.779,16.396,15.581,16.785c9.37,0.414,17.1-6.876,17.1-15.926v-15.552c47.316-9.689,40.086-78.181-8.327-78.181 h-16.028C99.312,239.66,99.306,224.408,109.638,224.408z"></path>{" "}
        </g>{" "}
      </g>{" "}
      <g>
        {" "}
        <g>
          {" "}
          <path d="M426.512,176.477H239.294c-8.388,0-15.747,6.145-16.667,14.482c-1.087,9.857,6.6,18.199,16.238,18.199h187.22 c8.388,0,15.747-6.145,16.667-14.482C443.838,184.819,436.15,176.477,426.512,176.477z"></path>{" "}
        </g>{" "}
      </g>{" "}
      <g>
        {" "}
        <g>
          {" "}
          <path d="M426.512,240.749H239.294c-8.388,0-15.747,6.145-16.667,14.482c-1.087,9.857,6.6,18.199,16.238,18.199h187.22 c8.388,0,15.747-6.145,16.667-14.482C443.838,249.091,436.15,240.749,426.512,240.749z"></path>{" "}
        </g>{" "}
      </g>{" "}
      <g>
        {" "}
        <g>
          {" "}
          <path d="M332.688,302.843h-93.395c-8.388,0-15.747,6.145-16.667,14.482c-1.086,9.857,6.602,18.199,16.239,18.199h93.395 c8.388,0,15.747-6.145,16.667-14.482C350.013,311.185,342.325,302.843,332.688,302.843z"></path>{" "}
        </g>{" "}
      </g>{" "}
    </g>
  </svg>
);
