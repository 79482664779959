import React from "react";
import cn from "classnames";
import styles from "../TopSection/TopSection.module.scss";

const TopSection = ({className, children}) => {
  return (
    <div className={cn(styles.topSection, className)}>
        <div className={styles.filter}></div>
        <div className={styles.content}>
          {children}
        </div>
      </div>
  );
};

export default TopSection;
