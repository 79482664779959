import React from "react";
import Container from "../../Container/Container";
import CallToAction from "../../CallToAction/CallToAction";
import UlList from "../../UlList/UlList";
import ServicesGrid from "../../ServicesGrid/ServicesGrid";
// import logo from "../../../img/Oxull_image.png";
import { Click } from "../../../_icons/Click";
import { BankCard } from "../../../_icons/BankCard";
import { Eye } from "../../../_icons/Eye";
import { People } from "../../../_icons/People";
import { Search } from "../../../_icons/Search";
import { Optimization } from "../../../_icons/Optimization";
import { Strategy } from "../../../_icons/Strategy";
import { Seo } from "../../../_icons/Seo";
import { Link } from "../../../_icons/Link";
import TopSection from "../../TopSection/TopSection";
import styles from "../DigitalMarketing/DigitalMarketing.module.scss";

const DigitalMarketing = () => {
  return (
    <main>
      <TopSection className={styles.marketingBg}>
        <h1>Digital Marketing</h1>
        <h2>Digital Marketing Solutions</h2>
        <p>
          At Oxull Media Kft, we offer comprehensive digital marketing services
          designed to maximize your online presence and drive measurable
          results. Our strategies focus on performance-driven campaigns that
          cater to your specific business objectives.
        </p>
      </TopSection>
      <Container>
        <p className={styles.italic}>Key Advertising Models</p>
        <ServicesGrid>
          <li>
            <Click />
            <h3>Cost Per Click (CPC)</h3>
            <p>
              With our CPC campaigns, you only pay when a user clicks on your
              ad. This model is ideal for driving traffic to your website,
              generating leads, and increasing conversions. We create compelling
              ads that not only attract clicks but also resonate with your
              target audience, ensuring a higher return on investment.
            </p>
          </li>
          <li>
            <BankCard />
            <h3>Cost Per Acquisition (CPA)</h3>
            <p>
              Our CPA approach focuses on optimizing your ad spend by targeting
              users who are more likely to convert. You pay only when a
              specified action (like a purchase or sign-up) is completed. This
              performance-based model allows you to budget effectively while
              maximizing the effectiveness of your marketing efforts.
            </p>
          </li>
          <li>
            <Eye />
            <h3>Cost Per View (CPV)</h3>
            <p>
              In CPV campaigns, you pay for each view of your video content.
              This model is perfect for businesses looking to increase brand
              awareness and engagement through video marketing. We develop
              eye-catching video ads and distribute them across platforms that
              reach your ideal audience.
            </p>
          </li>
          <li>
            <People />
            <h3>Cost Per Mille (CPM)</h3>
            <p>
              CPM campaigns charge you for every thousand impressions of your
              ad. This model is suitable for brand awareness and visibility,
              ensuring your message reaches a broad audience. Our team crafts
              visually appealing ads and selects the right platforms to maximize
              your reach.
            </p>
          </li>
        </ServicesGrid>
        <h2>SEO Optimization</h2>
        <p>
          In addition to our paid advertising strategies, we prioritize search
          engine optimization (SEO) to enhance your organic visibility.
        </p>
        <p className={styles.italic}>Our SEO services include:</p>
        <ServicesGrid>
          <li>
            <Search />
            <h3>Keyword Research</h3>
            <p>
              Identifying the most relevant keywords to target for your
              business, ensuring you rank higher in search results.
            </p>
          </li>
          <li>
            <Optimization width={"100%"} height={"100%"} />
            <h3>On-Page Optimization</h3>
            <p>
              Improving your website's structure, meta tags, content, and
              internal linking to enhance its search engine friendliness.
            </p>
          </li>
          <li>
            <Strategy width={"100%"} height={"100%"} />
            <h3>Content Strategy</h3>
            <p>
              Developing high-quality, engaging content that attracts and
              retains visitors, while also improving your search rankings.
            </p>
          </li>
          <li>
            <Seo />
            <h3>Technical SEO</h3>
            <p>
              Ensuring your website’s backend is optimized for search engines,
              including mobile-friendliness, page speed, and site architecture.
            </p>
          </li>
          <li>
            <Link />
            <h3>Link Building</h3>
            <p>
              Creating a robust backlink profile that enhances your site's
              authority and search rankings.
            </p>
          </li>
        </ServicesGrid>
        <h2>Why Choose Us for Digital Marketing?</h2>
        <UlList>
          <li>
            Tailored Strategies: We understand that each business is unique, and
            we customize our marketing strategies to align with your specific
            goals.
          </li>
          <li>
            Data-Driven Decisions: We leverage analytics and insights to
            continually refine and optimize your campaigns, ensuring the best
            possible outcomes.
          </li>
          <li>
            Transparent Reporting: We provide regular reports on your campaign
            performance, keeping you informed about key metrics and ROI.
          </li>
        </UlList>
        <h2>Let’s Elevate Your Digital Marketing!</h2>
        <CallToAction>
          Ready to enhance your digital marketing efforts? Contact us today to
          discuss how our targeted campaigns and SEO strategies can help you
          achieve your business objectives.
        </CallToAction>
      </Container>
    </main>
  );
};

export default DigitalMarketing;
