export const Table = ({ width, height, className }) => (
  <svg
    className={className}
    fill="#d75e0d"
    height={height || "200px"}
    width={width || "200px"}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <g>
        {" "}
        <g>
          {" "}
          <path d="M494.933,23.893H17.067C7.641,23.893,0,31.535,0,40.96v430.08c0,9.425,7.641,17.067,17.067,17.067h477.867 c9.425,0,17.067-7.641,17.067-17.067V40.96C512,31.535,504.359,23.893,494.933,23.893z M138.809,453.973H34.133V149.049h104.676 V453.973z M477.867,453.973H172.942V149.049h304.924V453.973z M477.867,114.916H155.886H34.133V58.027h443.733V114.916z"></path>{" "}
        </g>{" "}
      </g>{" "}
      <g>
        {" "}
        <g>
          {" "}
          <circle cx="64.865" cy="86.446" r="11.378"></circle>{" "}
        </g>{" "}
      </g>{" "}
      <g>
        {" "}
        <g>
          {" "}
          <circle cx="110.376" cy="86.446" r="11.378"></circle>{" "}
        </g>{" "}
      </g>{" "}
      <g>
        {" "}
        <g>
          {" "}
          <circle cx="155.887" cy="86.446" r="11.378"></circle>{" "}
        </g>{" "}
      </g>{" "}
      <g>
        {" "}
        <g>
          {" "}
          <path d="M106.567,201.387H66.834c-8.761,0-16.447,6.418-17.408,15.126c-1.135,10.295,6.894,19.008,16.96,19.008h39.732 c8.761,0,16.447-6.418,17.408-15.126C124.662,210.1,116.631,201.387,106.567,201.387z"></path>{" "}
        </g>{" "}
      </g>{" "}
      <g>
        {" "}
        <g>
          {" "}
          <path d="M106.567,284.444H66.834c-8.761,0-16.447,6.418-17.408,15.126c-1.135,10.295,6.894,19.008,16.96,19.008h39.732 c8.761,0,16.447-6.418,17.408-15.126C124.662,293.158,116.631,284.444,106.567,284.444z"></path>{" "}
        </g>{" "}
      </g>{" "}
      <g>
        {" "}
        <g>
          {" "}
          <path d="M106.567,367.502H66.834c-8.761,0-16.447,6.418-17.408,15.126c-1.135,10.295,6.894,19.008,16.96,19.008h39.732 c8.761,0,16.447-6.418,17.408-15.126C124.662,376.215,116.631,367.502,106.567,367.502z"></path>{" "}
        </g>{" "}
      </g>{" "}
      <g>
        {" "}
        <g>
          {" "}
          <path d="M367.857,301.1c41.653-38.535,14.341-108.5-42.453-108.5c-56.818,0-84.095,69.973-42.453,108.5 c-12.365,11.439-20.125,27.789-20.125,45.922v45.511c0,9.425,7.641,17.067,17.067,17.067h91.022 c9.425,0,17.067-7.641,17.067-17.067v-45.511C387.982,328.888,380.223,312.54,367.857,301.1z M325.409,227.136 c37.59,0,37.59,56.889,0,56.889S287.818,227.136,325.409,227.136z M353.849,375.467H296.96v-27.857 c0-14.513,11.791-27.864,26.263-28.95c16.665-1.249,30.626,11.957,30.626,28.363V375.467z"></path>{" "}
        </g>{" "}
      </g>{" "}
    </g>
  </svg>
);
