import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "../Menu/Menu.module.scss";
import { Close } from "../../_icons/Close";
import { Burger } from "../../_icons/Burger";

const BurgerMenu = ({ className, children }) => {
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);

  const clickHandle = () => {
    setIsBurgerMenuOpen(!isBurgerMenuOpen);
  };

  const menuStyles = cn(styles.burgerMenuWrapper, className, {
    [styles.burgerMenuWrapper__open]: isBurgerMenuOpen,
  });

  useEffect(() => {
    if (isBurgerMenuOpen) {
      document.body.classList.add('no-scroll'); // Add the no-scroll class
    } else {
      document.body.classList.remove('no-scroll'); // Remove the no-scroll class
    }
  }, [isBurgerMenuOpen]);
  
  return (
    <>
      <button onClick={clickHandle} className={styles.button}>
        {isBurgerMenuOpen ? <Close /> :<Burger/>}
      </button>
      <ul  onClick={clickHandle} className={menuStyles}>
        {children}
      </ul>
    </>
  );
};

export default BurgerMenu;
