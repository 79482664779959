import React from "react";
import logo from "../../../img/Oxull_logo.png";
import icon from "../../../img/Oxull_icon.png";
import Image from "../../Image/Image";
import styles from "../Home/Home.module.scss";
import Container from "../../Container/Container";
import LinkButton from "../../LinkButton/LinkButton";
import CallToAction from "../../CallToAction/CallToAction";
import UlList from "../../UlList/UlList";
import TopSection from "../../TopSection/TopSection";
import ItemsGrid from "../../ItemsGrid/ItemsGrid";

const Home = () => {
  return (
    <main>
      <TopSection>
        <Image logo={logo} />
        <h1>Welcome to Oxull Media Kft</h1>
        <p>
          At Oxull Media Kft, we specialize in empowering your business through
          innovative digital advertising, cutting-edge software development, and
          strategic consulting.
        </p>
      </TopSection>

      <Container className={styles.localContainer}>
        <ItemsGrid>
          <div>
            <h2>Transforming Ideas into Impact</h2>
            <Image logo={icon} />
            <p>
              In today’s digital landscape, standing out is more important than
              ever. Our dedicated team combines creativity and technology to
              craft tailored advertising solutions that drive engagement and
              results. Whether you're looking to enhance your online presence or
              launch a targeted campaign, we’re here to help you make a lasting
              impression.
            </p>
            <LinkButton href={"/marketing"} title={"Learn More"} />
          </div>
          <div>
            <h2>Custom Software Solutions</h2>
            <Image logo={icon} />
            <p>
              From concept to execution, our software development services are
              designed to meet your unique needs. We build robust, scalable
              applications that streamline your operations and enhance user
              experiences. Our agile approach ensures that your project is
              delivered on time and within budget.
            </p>
            <LinkButton href={"/development"} title={"Learn More"} />
          </div>
          <div>
            <h2>Strategic Consulting for Growth</h2>
            <Image logo={icon} />
            <p>
              Navigating the complexities of digital marketing and technology
              can be daunting. Our consulting experts are here to guide you
              through every step, providing insights and strategies that align
              with your business goals. We collaborate closely with you to
              develop solutions that not only solve problems but also drive
              growth.
            </p>
            <LinkButton href={"/consulting"} title={"Learn More"} />
          </div>
        </ItemsGrid>
        <div>
          <h2>Why Choose Us?</h2>
          <UlList>
            <li>
              Expertise: Our team is comprised of industry veterans with years
              of experience.
            </li>
            <li>
              Tailored Solutions: We believe in customized strategies that fit
              your unique needs.
            </li>
            <li>
              Results-Driven: We measure success through the tangible results we
              deliver for your business.
            </li>
          </UlList>
        </div>
        <h2>Let’s Connect</h2>
        <CallToAction>
          <p>
            Ready to take your digital presence to the next level? Contact us
            today to learn how Oxull Media Kft can help you thrive in the
            digital world.
          </p>
        </CallToAction>
      </Container>
    </main>
  );
};

export default Home;
