export const Optimization = ({ width, height, className }) => (
  <svg
    className={className}
    fill="#d75e0d"
    height={height || "200px"}
    width={width || "200px"}
    version="1.1"
    id="XMLID_223_"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <g id="optimization">
        {" "}
        <g>
          {" "}
          <path d="M17,24V11h6v13H17z M19,13v9h2v-9H19z M15,24H9v-8h6V24z M11,22h2v-4h-2V22z M7,24H1v-6h6V24z M3,22h2v-2H3V22z M1,16v-2 c7.8,0,12.8-3.4,16.1-10.9l-4.3,1.7L12,2.9L19.5,0l3,7.2L20.6,8l-1.7-4.2C15.3,12.1,9.6,16,1,16z"></path>{" "}
        </g>{" "}
      </g>{" "}
    </g>
  </svg>
);
