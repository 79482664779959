export const Advertising = ({ width, height, className }) => (
  <svg
    className={className}
    width={width || "256px"}
    height={height || "256px"}
    viewBox="0 0 32 32"
    enableBackground="new 0 0 32 32"
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    fill="#000000"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <g id="advertising_1_">
        {" "}
        <path
          d="M23.5,17.092c-0.055,0-0.111-0.009-0.165-0.028C23.134,16.994,23,16.805,23,16.592V13h-2.5 c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h3c0.276,0,0.5,0.224,0.5,0.5v2.658l2.358-2.969C26.453,12.069,26.598,12,26.75,12 h2.75c0.276,0,0.5-0.225,0.5-0.5v-9C30,2.225,29.776,2,29.5,2h-15C14.224,2,14,2.225,14,2.5v9c0,0.275,0.224,0.5,0.5,0.5h2 c0.276,0,0.5,0.224,0.5,0.5S16.776,13,16.5,13h-2c-0.827,0-1.5-0.673-1.5-1.5v-9C13,1.673,13.673,1,14.5,1h15 C30.327,1,31,1.673,31,2.5v9c0,0.827-0.673,1.5-1.5,1.5h-2.509l-3.1,3.902C23.795,17.024,23.649,17.092,23.5,17.092z"
          fill="#d75e0d"
        ></path>{" "}
        <g>
          {" "}
          <g>
            {" "}
            <g>
              {" "}
              <path
                d="M18.5,17.996v1c1.103,0,2,0.897,2,2s-0.897,2-2,2v1c1.654,0,3-1.346,3-3S20.154,17.996,18.5,17.996z"
                fill="#d75e0d"
              ></path>{" "}
            </g>{" "}
            <path
              d="M6.927,24.563l0.485,5.135l-1.519,0.305L5.865,30.03l-0.839-5.468h-1.01l0.873,5.673 c0.12,0.451,0.532,0.767,1.002,0.767c0.065,0,0.132-0.006,0.198-0.02l1.519-0.305c0.264-0.052,0.489-0.204,0.636-0.428 c0.147-0.224,0.197-0.491,0.15-0.696l-0.465-4.991H6.927z"
              fill="#d75e0d"
            ></path>{" "}
            <g>
              {" "}
              <path
                d="M8.5,18C8.224,18,8,17.776,8,17.5S8.224,17,8.5,17c2.556,0,5.222-0.97,7.709-2.806 c0.223-0.163,0.535-0.116,0.699,0.105c0.164,0.223,0.117,0.535-0.105,0.699C14.143,16.962,11.271,18,8.5,18z"
                fill="#d75e0d"
              ></path>{" "}
              <path
                d="M18.5,29.607c-0.135,0-0.271-0.055-0.369-0.163c-0.041-0.044-4.137-4.448-9.668-4.448H3.375 C2.11,24.996,1,23.881,1,22.609v-3.252c0-1.28,1.088-2.361,2.375-2.361L6.5,17C6.777,17,7,17.225,7,17.501 C7,17.776,6.776,18,6.5,18h0l-3.125-0.004C2.63,17.996,2,18.619,2,19.357v3.252c0,0.726,0.656,1.387,1.375,1.387h5.087 c5.981,0,10.229,4.579,10.407,4.774c0.186,0.204,0.172,0.52-0.032,0.706C18.741,29.563,18.621,29.607,18.5,29.607z"
                fill="#d75e0d"
              ></path>{" "}
            </g>{" "}
            <rect
              fill="#d75e0d"
              height="6.918"
              width="1"
              x="8"
              y="17.537"
            ></rect>{" "}
            <g>
              {" "}
              <path
                d="M18.5,29.996c-0.276,0-0.5-0.224-0.5-0.5v-17c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v17 C19,29.772,18.776,29.996,18.5,29.996z"
                fill="#d75e0d"
              ></path>{" "}
            </g>{" "}
            <g>
              {" "}
              <path
                d="M4.5,22h-1C3.224,22,3,21.776,3,21.5S3.224,21,3.5,21h1C4.776,21,5,21.224,5,21.5S4.776,22,4.5,22z"
                fill="#d75e0d"
              ></path>{" "}
            </g>{" "}
            <g>
              {" "}
              <path
                d="M4.5,20h-1C3.224,20,3,19.776,3,19.5S3.224,19,3.5,19h1C4.776,19,5,19.224,5,19.5S4.776,20,4.5,20z"
                fill="#d75e0d"
              ></path>{" "}
            </g>{" "}
          </g>{" "}
        </g>{" "}
        <g>
          {" "}
          <g>
            {" "}
            <path d="M19,10.5l6-7L19,10.5z" fill="#d75e0d"></path>{" "}
            <path
              d="M19,11c-0.115,0-0.231-0.039-0.325-0.12c-0.21-0.18-0.234-0.496-0.054-0.705l6-7 c0.181-0.21,0.496-0.233,0.705-0.055c0.21,0.18,0.234,0.496,0.054,0.705l-6,7C19.281,10.94,19.141,11,19,11z"
              fill="#d75e0d"
            ></path>{" "}
          </g>{" "}
          <path
            d="M24.5,11c-0.451,0-1.5-0.195-1.5-2c0-1.74,0.94-2,1.5-2S26,7.26,26,9C26,10.805,24.951,11,24.5,11z M24.5,8C24.313,8,24,8,24,9s0.363,1,0.5,1S25,10,25,9S24.687,8,24.5,8z"
            fill="#d75e0d"
          ></path>{" "}
          <path
            d="M19.5,7C19.049,7,18,6.805,18,5c0-1.74,0.94-2,1.5-2S21,3.26,21,5C21,6.805,19.951,7,19.5,7z M19.5,4 C19.313,4,19,4,19,5s0.363,1,0.5,1S20,6,20,5S19.687,4,19.5,4z"
            fill="#d75e0d"
          ></path>{" "}
        </g>{" "}
      </g>{" "}
    </g>
  </svg>
);
