import React from "react";
import cn from "classnames";
import styles from "../Image/Image.module.scss";

const Image = ({logo, className, children}) => {

  return (
    <div className={cn(styles.imageWrapper, className)}>
      {children}
      <img src={logo} alt="logo" className={styles.image} />
    </div>
  );
};

export default Image;
