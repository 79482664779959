import React from "react";
import TopSection from "../../TopSection/TopSection";
import Container from "../../Container/Container";
import styles from "../Contacts/Contacts.module.scss";
import ItemsGrid from "../../ItemsGrid/ItemsGrid";
import { Address } from "../../../_icons/Address";
import { Vat } from "../../../_icons/Vat";
import { Phone } from "../../../_icons/Phone";
import { Email } from "../../../_icons/Email";
import cn from "classnames";

const Contacts = () => {
  return (
    <main>
      <TopSection className={cn(styles.sectionBg, styles.custonSection)}>
        <h1>Contacts</h1>
        <Container className={styles.customContainer}>
          <h2>Oxull Media Kft.</h2>
          <ItemsGrid className={styles.grid}>
            <div>
              <Address className={styles.icon} />
              <h3>Address</h3>
              <p>Hungary, 2724 Ujlengyel, Dozsa Gyorgy utca 11.</p>
            </div>
            <div>
              <Vat className={styles.icon} width={"50px"} height={"50px"} />

              <h3>EU VAT</h3>
              <p>HU32175482</p>
            </div>
            <div>
              <Phone className={styles.icon} />

              <h3>Phone</h3>
              <p>+36301311520</p>
            </div>
            <div>
              <Email className={styles.icon} />
              <h3>Email</h3>
              <p>director@oxullmedia.com</p>
            </div>
          </ItemsGrid>
        </Container>
      </TopSection>
    </main>
  );
};

export default Contacts;
