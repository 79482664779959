import React from "react";
import Container from "../../Container/Container";
import UlList from "../../UlList/UlList";
import CallToAction from "../../CallToAction/CallToAction";
import Image from "../../Image/Image";
import advImage from "../../../img/Oxull_consalting_adv_img.png";
import itImage from "../../../img/Oxull_consalting_it_img.png";
import styles from "../Consulting/Consulting.module.scss";
import TopSection from "../../TopSection/TopSection";
import OlList from "../../OlList/OlList";

const Consulting = () => {
  return (
    <main>
      <TopSection className={styles.sectionBg}>
        <h1>Consulting</h1>
        <h2>Consulting Services</h2>
        <p>
          At Oxull Media Kft, we provide expert consulting services that empower
          businesses to navigate the complexities of IT and advertising. Our
          seasoned consultants bring a wealth of experience and insight, helping
          you develop strategies that align with your goals and drive
          sustainable growth.
        </p>
      </TopSection>
      <Container className={styles.customContainer}>
        <h3>IT Consulting</h3>
        <p>
          In the rapidly evolving world of technology, having a strategic IT
          partner is crucial. Our IT consulting services include:
        </p>
        <div className={styles.twoColumnsTile}>
          <Image logo={itImage} className={styles.img} />
          {/* <ol className={styles.olList}> */}
            <OlList>
            <li>
              <strong>IT Strategy Development: </strong>We work with you to
              create a comprehensive IT strategy that aligns with your business
              objectives, ensuring that
            </li>
            <li>
              <strong>System Integration: </strong>Our consultants help you
              seamlessly integrate new technologies with existing systems,
              ensuring smooth operations and maximizing efficiency.
            </li>
            <li>
              <strong>Cloud Solutions: </strong>We guide you in adopting cloud
              technologies, helping you leverage the benefits of scalability,
              flexibility, and cost savings while ensuring data security and
              compliance.
            </li>
            <li>
              <strong>Cybersecurity Assessment</strong>Our experts conduct
              thorough assessments of your current cybersecurity posture,
              identifying vulnerabilities and implementing robust security
              measures to protect your business.
            </li>
            <li>
              <strong>Technology Roadmap</strong>We develop a clear technology
              roadmap that outlines the steps needed to achieve your IT goals,
              including timelines, resource allocation, and risk management
              strategies.
            </li>
            </OlList>
        </div>

        <h3>Advertising Consulting</h3>
        <p>
          In the dynamic landscape of digital marketing, our advertising
          consulting services provide the insights and strategies you need to
          stand out. We offer:
        </p>
        <div className={styles.twoColumnsTile}>
          <Image logo={advImage} className={styles.img} />
          <OlList>
            <li>
              <strong>Market Research and Analysis: </strong>Our team conducts
              in-depth market research to identify trends, audience segments,
              and competitive landscapes, enabling you to make informed
              advertising decisions.
            </li>
            <li>
              <strong>Campaign Strategy Development: </strong>We collaborate
              with you to design effective advertising campaigns that resonate
              with your target audience, utilizing the most suitable channels
              and messaging.
            </li>
            <li>
              <strong>Performance Optimization: </strong>Our consultants analyze
              your current advertising efforts, identifying areas for
              improvement and implementing strategies to enhance performance and
              ROI.
            </li>
            <li>
              <strong>Brand Positioning: </strong>We help you define and
              strengthen your brand's unique value proposition, ensuring that
              your messaging is consistent and impactful across all platforms.
            </li>
            <li>
              <strong>Training and Workshops: </strong>We offer tailored
              training sessions and workshops for your team, equipping them with
              the latest tools and techniques in advertising and digital
              marketing.
            </li>
          </OlList>
        </div>

        <h2>Why Choose Us for Consulting?</h2>
        <UlList>
          <li>
            Experienced Professionals: Our team consists of industry experts
            with years of experience in IT and advertising, providing you with
            insights that matter.
          </li>
          <li>
            Tailored Solutions: We understand that each business is unique, and
            we customize our consulting services to meet your specific needs.
          </li>
          <li>
            Data-Driven Approach: We leverage data and analytics to inform our
            recommendations, ensuring that your strategies are grounded in
            measurable insights.
          </li>
          <li>
            Long-Term Partnership: We’re committed to building lasting
            relationships with our clients, providing ongoing support and
            guidance as your business evolves.
          </li>
        </UlList>
        <h2>Ready to Elevate Your Business?</h2>
        <CallToAction>
          Contact us today to learn how our IT and advertising consulting
          services can help you achieve your business goals and navigate the
          complexities of the digital landscape!
        </CallToAction>
      </Container>
    </main>
  );
};

export default Consulting;
