import React from "react";
import logo from "../../img/Oxull_logo_secondary.png";
import Image from "../Image/Image";
import styles from "../Footer/Footer.module.scss";

const Footer = () => {
  return (
    <footer className={styles.footerWrapper}>
      <Image logo={logo}/>
      <p>© 2024 All Rights Reserved.</p>
      <ul className={styles.contactData}>
        <li>Oxull Media Kft.</li>
        <li>Address: Hungary, 2724 Ujlengyel, Dozsa Gyorgy utca 11.</li>
        <li>Phone: +36301311520</li>
        <li>Email: director@oxullmedia.com</li>
      </ul>
      <p>EU VAT: HU32175482</p>
    </footer>
  );
};

export default Footer;
