import React from "react";
import cn from "classnames";
import styles from "../Container/Container.module.scss";

const Container = ({className, children}) => {
  return (
    <div className={cn(styles.container, className)}>
      {children}
    </div>
  );
};

export default Container;
