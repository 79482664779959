import React from "react";
import cn from "classnames";
import styles from "../OlList/OlList.module.scss";

const OlList = ({className, children}) => {
  return (
    <ul className={cn(styles.olList, className)}>
      {children}
    </ul>
  );
};

export default OlList;
