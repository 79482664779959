import React from "react";
import cn from "classnames";
import styles from "../CallToAction/CallToAction.module.scss";

const CallToAction = ({className, children}) => {
  return (
    <div className={cn(styles.callToAction, className)}>
      {children}
    </div>
  );
};

export default CallToAction;
