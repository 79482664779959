import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/pages/Home/Home';
import DigitalMarketing from './components/pages/DigitalMarketing/DigitalMarketing';
import SoftwareDevelopment from './components/pages/SoftwareDevelopment/SoftwareDevelopment';
import Consulting from './components/pages/Consulting/Consulting';
import Contacts from './components/pages/Contacts/Contacts';
import Layout from './components/Layout/Layout';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/marketing" element={<DigitalMarketing />} />
          <Route path="/development" element={<SoftwareDevelopment />} />
          <Route path="/consulting" element={<Consulting />} />
          <Route path="/contacts" element={<Contacts />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
