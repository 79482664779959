import React from "react";
import cn from "classnames";
import styles from "../LinkButton/LinkButton.module.scss";
import { Link } from "react-router-dom";

const LinkButton = ({ className, title, href }) => {
  return (
    <Link className={cn(styles.button, className)} to={href}>
      {title}
    </Link>
  );
};

export default LinkButton;
