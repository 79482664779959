import React from "react";
import logo from "../../img/Oxull_logo.png";
import Image from "../Image/Image";
import styles from "../Header/Header.module.scss";
import Menu from "../Menu/Menu";
import BurgerMenu from "../Menu/BurgerMenu";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header className={styles.headerWrapper}>
      <Link to={"/"} className={styles.logoWrapper}>
        <Image logo={logo} />
      </Link>
      <Menu>
        <li>
          <Link to="/marketing">Digital Marketing</Link>
        </li>
        <li>
          <Link to="/development">Software development</Link>
        </li>
        <li>
          <Link to="/consulting">Consulting</Link>
        </li>
        <li>
          <Link to="/contacts">Contacts</Link>
        </li>
      </Menu>
      <BurgerMenu>
      <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/marketing">Digital Marketing</Link>
        </li>
        <li>
          <Link to="/development">Software development</Link>
        </li>
        <li>
          <Link to="/consulting">Consulting</Link>
        </li>
        <li>
          <Link to="/contacts">Contacts</Link>
        </li>
      </BurgerMenu>
    </header>
  );
};

export default Header;
