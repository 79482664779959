export const Seo = ({ width, height, className }) => (
  <svg
    className={className}
    fill="#d75e0d"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64.325 64.325"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <g>
        {" "}
        <g>
          {" "}
          <path d="M5.865,34.72c-1.906,0-3.754-0.614-4.957-1.231L0,37.184c1.114,0.616,3.344,1.202,5.602,1.202 c5.426,0,7.977-2.815,7.977-6.129c0-2.786-1.643-4.604-5.132-5.895c-2.552-0.969-3.666-1.525-3.666-2.786 c0-1.027,0.938-1.907,2.874-1.907s3.344,0.558,4.135,0.939l0.998-3.607c-1.174-0.528-2.816-0.997-5.045-0.997 c-4.663,0-7.479,2.58-7.479,5.953c0,2.874,2.141,4.692,5.426,5.836c2.375,0.851,3.314,1.555,3.314,2.785 C9.004,33.87,7.918,34.72,5.865,34.72z"></path>{" "}
          <polygon points="29.211,34.427 21.088,34.427 21.088,29.735 28.36,29.735 28.36,26.098 21.088,26.098 21.088,21.992 28.801,21.992 28.801,18.326 16.6,18.326 16.6,38.093 29.211,38.093 "></polygon>{" "}
          <path d="M44.326,39.593c2.775,0,5.318-0.999,7.298-2.653l3.937,3.674c-0.006,0.41,0.143,0.816,0.465,1.117l5.777,5.394 c0.289,0.27,0.655,0.403,1.022,0.403c0.401,0,0.802-0.16,1.097-0.478c0.565-0.604,0.532-1.556-0.072-2.12l-5.777-5.393 c-0.322-0.301-0.74-0.42-1.146-0.387l-3.885-3.625c1.671-1.983,2.684-4.541,2.684-7.332c0-6.285-5.112-11.397-11.397-11.397 S32.929,21.91,32.929,28.194C32.928,34.481,38.041,39.593,44.326,39.593z M44.326,19.797c4.631,0,8.398,3.767,8.398,8.397 c0,4.63-3.769,8.398-8.398,8.398s-8.398-3.768-8.398-8.398C35.928,23.564,39.695,19.797,44.326,19.797z"></path>{" "}
        </g>{" "}
      </g>{" "}
    </g>
  </svg>
);
