import React from "react";
import Container from "../../Container/Container";
import ServicesGrid from "../../ServicesGrid/ServicesGrid";
import UlList from "../../UlList/UlList";
import CallToAction from "../../CallToAction/CallToAction";
import { Advertising } from "../../../_icons/Advertising";
import { Software } from "../../../_icons/Software";
import { Design } from "../../../_icons/Design";
import { Gamepad } from "../../../_icons/Gamepad";
import { Android } from "../../../_icons/Android";
import { Table } from "../../../_icons/Table";
import TopSection from "../../TopSection/TopSection";
import styles from "../SoftwareDevelopment/SoftwareDevelopment.module.scss";

const SoftwareDevelopment = () => {
  return (
    <main>
      <TopSection className={styles.sectionBg}>
        <h1>Software development</h1>
        <h2>Software Development Solutions</h2>
        <p>
          At Oxull Media Kft, we offer a comprehensive range of software
          development services tailored to meet the unique needs of your
          business. Our team of skilled developers and designers is committed to
          delivering high-quality solutions that drive innovation and enhance
          user experiences.
        </p>
      </TopSection>
      <Container>
        <h3>Custom Software Development</h3>
        <p>
          We specialize in building bespoke software solutions that address your
          specific business challenges. Our development process includes:
        </p>
        <ServicesGrid>
          <li>
            <Software />
            <h3>Requirements Gathering</h3>
            <p>
              We work closely with you to understand your needs and objectives.
            </p>
          </li>
          <li>
            <Software />
            <h3>Agile Development</h3>
            <p>
              Utilizing agile methodologies, we ensure flexibility and
              adaptability throughout the development process.
            </p>
          </li>
          <li>
            <Software />
            <h3>Quality Assurance</h3>
            <p>
              Rigorous testing ensures that our software meets the highest
              standards of performance and reliability.
            </p>
          </li>
          <li>
            <Software />
            <h3>Post-Launch Support</h3>
            <p>
              Our commitment doesn’t end at launch; we provide ongoing support
              and maintenance to keep your software running smoothly.
            </p>
          </li>
        </ServicesGrid>
        <h3>Website Design and Development</h3>
        <p>
          Your website is often the first impression customers have of your
          business. We create visually stunning and user-friendly websites that
          are optimized for performance. Our services include:
        </p>
        <ServicesGrid>
          <li>
            <Design />
            <h3>Responsive Design</h3>
            <p>
              Ensuring your website looks great and functions seamlessly across
              all devices.
            </p>
          </li>
          <li>
            <Design />
            <h3>Content Management Systems (CMS)</h3>
            <p>
              We build websites on popular platforms like WordPress, Joomla, and
              custom solutions for easy content management.
            </p>
          </li>
          <li>
            <Design />
            <h3>E-Commerce Solutions</h3>
            <p>
              Creating secure and scalable online stores with intuitive shopping
              experiences.
            </p>
          </li>
          <li>
            <Design />
            <h3>SEO Optimization</h3>
            <p>
              Designing with SEO best practices in mind to help improve your
              visibility in search engines.
            </p>
          </li>
        </ServicesGrid>
        <h3>Game Development</h3>
        <p>
          With a passion for creativity and technology, our game development
          team crafts engaging and immersive gaming experiences. Our services
          include:
        </p>
        <ServicesGrid>
          <li>
            <Gamepad />
            <h3>Concept Development</h3>
            <p>
              From initial ideas to full-fledged game concepts, we help bring
              your vision to life.
            </p>
          </li>
          <li>
            <Gamepad />
            <h3>Cross-Platform Development</h3>
            <p>
              Creating games for multiple platforms, including mobile, PC, and
              consoles, to reach a broader audience.
            </p>
          </li>
          <li>
            <Gamepad />
            <h3>Game Design</h3>
            <p>
              Our designers focus on gameplay mechanics, user interface, and
              overall player experience to ensure your game is both fun and
              engaging.
            </p>
          </li>
          <li>
            <Gamepad />
            <h3>Testing and Launch</h3>
            <p>
              We rigorously test your game for bugs and performance issues
              before launching to ensure a smooth experience for players.
            </p>
          </li>
        </ServicesGrid>
        <h3>App Development</h3>
        <p>
          In a mobile-first world, having a powerful app can set your business
          apart. We offer comprehensive app development services that include:
        </p>
        <ServicesGrid>
          <li>
            <Android />
            <h3>iOS and Android Development</h3>
            <p>
              Building native and cross-platform applications that perform
              seamlessly on all devices.
            </p>
          </li>
          <li>
            <Android />
            <h3>User-Centric Design</h3>
            <p>
              Focusing on intuitive interfaces and user experiences that keep
              your audience engaged.
            </p>
          </li>
          <li>
            <Android />
            <h3>Integration Services</h3>
            <p>
              Ensuring your app connects smoothly with existing systems and
              third-party services.
            </p>
          </li>
          <li>
            <Android />
            <h3>Maintenance and Updates</h3>
            <p>
              Providing ongoing support and updates to enhance functionality and
              keep up with evolving user needs.
            </p>
          </li>
        </ServicesGrid>
        <h3>CRM Development</h3>
        <p>
          Customer Relationship Management (CRM) systems are essential for
          managing your interactions with clients and prospects. Our CRM
          development services include:
        </p>
        <ServicesGrid>
          <li>
            <Table width="100%" height="100%" />
            <h3>Custom CRM Solutions</h3>
            <p>
              We develop tailored CRM systems that meet your specific business
              requirements, improving customer relationships and sales
              processes.
            </p>
          </li>
          <li>
            <Table width="100%" height="100%" />
            <h3>Integration with Existing Tools</h3>
            <p>
              Seamlessly integrating your CRM with other business tools to
              create a unified workflow.
            </p>
          </li>
          <li>
            <Table width="100%" height="100%" />
            <h3>Data Analytics</h3>
            <p>
              Implementing robust analytics features to help you track customer
              interactions, preferences, and trends for informed
              decision-making.
            </p>
          </li>
          <li>
            <Table width="100%" height="100%" />
            <h3>User Training and Support</h3>
            <p>
              Providing training for your team and ongoing support to ensure
              smooth adoption of the system.
            </p>
          </li>
        </ServicesGrid>
        <h3>Advertising Platform Development</h3>
        <p>
          In a competitive digital landscape, having a dedicated advertising
          platform can give your business an edge. Our advertising platform
          development services include:
        </p>
        <ServicesGrid>
          <li>
            <Advertising width="100%" height="100%" />
            <h3>Custom Platform Creation</h3>
            <p>
              Building tailored advertising platforms that allow you to manage,
              track, and optimize your advertising campaigns effectively.
            </p>
          </li>
          <li>
            <Advertising width="100%" height="100%" />
            <h3>Real-Time Analytics</h3>
            <p>
              Implementing advanced analytics to provide insights into campaign
              performance, enabling data-driven decisions.
            </p>
          </li>
          <li>
            <Advertising width="100%" height="100%" />
            <h3>User Management and Segmentation</h3>
            <p>
              Developing features that allow for user segmentation and targeted
              advertising, ensuring the right message reaches the right
              audience.
            </p>
          </li>
          <li>
            <Advertising width="100%" height="100%" />
            <h3>Integration with Ad Networks</h3>
            <p>
              Connecting your platform with various ad networks for a broader
              reach and enhanced campaign effectiveness.
            </p>
          </li>
        </ServicesGrid>
        <h2>Why Choose Us for Software Development?</h2>
        <UlList>
          <li>
            Expert Team: Our developers and designers are experienced
            professionals dedicated to delivering excellence.
          </li>
          <li>
            Tailored Solutions: We understand that one size does not fit all;
            our solutions are customized to meet your specific requirements.
          </li>
          <li>
            Cutting-Edge Technologies: We stay up-to-date with the latest
            technologies and trends to ensure your project is innovative and
            competitive.
          </li>
        </UlList>
        <h2>Ready to Transform Your Ideas into Reality?</h2>
        <CallToAction>
          Contact us today to discuss how our software development, website
          design, game development, app development, CRM solutions, and
          advertising platform services can help elevate your business!
        </CallToAction>
      </Container>
    </main>
  );
};

export default SoftwareDevelopment;
